<template>
  <div class="card card-custom">
    <div class="card-header mt-5 d-flex flex-column flex-sm-row justify-content-between align-items-center">
    <div class="card-title d-flex align-items-center mb-3 mb-sm-0">
      <span class="card-icon">
        <i class="flaticon2-chat-1 text-primary "></i>
      </span>
      <h3 class="card-label ml-2 font-poppins">All Orders</h3>
    </div>
    <v-row class="align-items-center justify-content-end no-gutters w-100">
      <v-col cols="12" sm="auto" v-if="isAnyFilterApplied" class="d-flex justify-end px-2 mb-2">
        <span class="text-indego cursor-pointer" @click="clearFilters">Clear Filters</span>
      </v-col>
      <v-col cols="12" sm="auto" class="px-2 mb-2">
        <v-text-field
          v-model="filterBusinessName"
          outlined
          dense
          hide-details
          placeholder="Business Name"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="auto" class="px-2 mb-2">
        <v-select
          v-model="filterOrderType"
          :items="orderTypes"
          outlined
          dense
          hide-details
          placeholder="Order Type"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="auto" class="px-2 mb-2">
        <v-select
          v-model="filterStatus"
          :items="statuses"
          outlined
          dense
          hide-details
          placeholder="Status"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="auto" class="px-2 mb-2 d-flex justify-end">
        <v-btn elevation="2" class="bg-primary text-light font-poppins"  @click="loadOrders">Search</v-btn>
      </v-col>
    </v-row>
  </div>
    <div class="card-body p-0">
      <div class="row my-5 px-2">
        <div class="col-sm-12">
          <v-data-table
            :headers="headers"
            :items="orders"
            :server-items-length="totalOrders"
            :options.sync="options"
            :loading="loading"
            class="elevation-1"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-1 text-indego" @click="viewDetails(item)">
                mdi-eye
              </v-icon>
            </template>
                    <template v-slot:footer>
        
          </template>
          </v-data-table>
          <v-pagination
              v-model="options.page"
              :length="Math.ceil(totalOrders / options.itemsPerPage)"
              @input="loadOrders"
              :total-visible="7"

            ></v-pagination>
        </div>
      </div>
    </div>

    <!-- Modal for Order Details -->
    <v-dialog v-model="dialog" max-width="800px" scrollable>
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-toolbar-title>Order Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container v-if="selectedOrder">
            <v-row>
              <!-- Existing Order Details Fields -->
              <v-col cols="6">
                <v-text-field v-model="selectedOrder.id" label="ID" disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="selectedOrder.business.name" label="Business Name" disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="selectedOrder.customers.name" label="Customer Name" disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="selectedOrder.customers.email" label="Customer Email" disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="selectedOrder.type" label="Order Type" disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="selectedOrder.collection_date" label="Collection Date" disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="selectedOrder.collection_time" label="Collection Time" disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="selectedOrder.delivery_date" label="Delivery Date" disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="selectedOrder.delivery_time" label="Delivery Time" disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="selectedOrder.current_status[0].name" label="Current Status" disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="selectedOrder.total" label="Total" disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="selectedOrder.payment_method" label="Payment Method" disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="selectedOrder.order_note" label="Order Note" disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="selectedOrder.delay" label="Delay" disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="selectedOrder.order_ip" label="IP" disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="selectedOrder.cancelled_by" label="Cancelled By" disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="selectedOrder.cancel_reason" label="Cancel Reason" disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="selectedOrder.cancel_date" label="Cancel Date" disabled></v-text-field>
              </v-col>
              <!-- Order Items Table -->
              <v-col cols="12">
                <h4>Order Items</h4>
                <v-data-table
  :headers="itemHeaders"
  :items="formattedOrderProducts"
  class="elevation-1"
>
  <template v-slot:item.toppings="{ item }">
    <div v-if="Array.isArray(item.toppings)">
      <div v-for="topping in item.toppings" :key="topping.topp_id">
        {{ topping.names }} - {{ topping.required_count }} - {{ topping.topp_price }}
      </div>
    </div>
  </template>
</v-data-table>

              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="bg-primary text-light" @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "businesses",
  data() {
    return {
      dialog: false,
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 5
      },
      totalOrders: 0,
      orders: [],
      selectedOrder: null,
      search: '',
      filterBusinessName: '',
      filterOrderType: '',
      filterStatus: '',
      businesses: [],
      orderTypes: ['Collection', 'Delivery'],
      statuses: ['Cancelled', 'Acknowledged', 'Order Prepared', 'Delivered', 'Pending', 'Collected', 'Order on Way'],
      headers: [
        { text: "ID", align: "left", sortable: false, value: "id" },
        { text: "Business Name", align: "left", sortable: false, value: "business.name" },
        { text: "Customer Name", align: "left", sortable: false, value: "customers.name" },
        { text: "Order Type", align: "left", sortable: false, value: "type" },
        { text: "Collection Date", align: "left", sortable: false, value: "collection_date" },
        { text: "Delivery Time", align: "left", sortable: false, value: "delivery_time" },
        { text: "Delivery Date", align: "left", sortable: false, value: "delivery_date" },
        { text: "Current Status", align: "left", sortable: false, value: "current_status[0].name" },
        { text: "Total", align: "left", sortable: false, value: "total" },
        { text: "Payment Method", align: "left", sortable: false, value: "payment_method" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      itemHeaders: [
        { text: "Order", align: "left", sortable: false, value: "order_id" },
        { text: "Order Date", align: "left", sortable: false, value: "orders.created_at" },
        { text: "Product", align: "left", sortable: false, value: "products.name" },
        { text: "Quantity", align: "left", sortable: false, value: "quantity" },
        { text: "Toppings", align: "left", sortable: false, value: "toppings" },
        { text: "Properties", align: "left", sortable: false, value: "properties" },
        { text: "Total Amount", align: "left", sortable: false, value: "amount" }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.loadOrders();
      },
      deep: true
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Customer Orders" }]);
    this.loadOrders();
  },
  computed: {
    isAnyFilterApplied() {
      return (
        this.filterBusinessName !== '' ||
        this.filterOrderType !== '' ||
        this.filterStatus !== ''
      );
    },
    formattedOrderProducts() {
      if (!this.selectedOrder || !Array.isArray(this.selectedOrder.order_products)) {
      return [];
    }

    return this.selectedOrder.order_products.map(product => {
      return {
        ...product,
        toppings: Array.isArray(product.toppings) ? product.toppings.map(topping => ({
          names: topping.names,
          required_count: topping.required_count,
          topp_price: topping.topp_price
        })) : []
      };
    });
    }
  },
  methods: {
    clearFilters() {
      this.filterBusinessName = '';
      this.filterOrderType = '';
      this.filterStatus = '';
      this.loadOrders();
    },
    loadOrders() {
    this.loading = true;
    ApiService.query("admin/orders", {
      params: {
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        business_name: this.filterBusinessName,
        order_type: this.filterOrderType,
        status: this.filterStatus
      }
    })
      .then(({ data }) => {
        this.orders = data.data;
        this.totalOrders = data.total;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
    viewDetails(order) {
      this.selectedOrder = order;
      this.dialog = true;
    }
  }
};
</script>
